import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Label,
  Loader,
  Placeholder,
  PlaceholderParagraph,
  Popup,
  Statistic,
  Table,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetDatasetRowCountQuery, useListDatasetsQuery } from 'src/api/datasets';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import { DatasetStatsCounts } from 'src/types';
import DeleteDatasetV2 from './DeleteDatasetV2';
import UploaToDatasetV2Modal from './UploaToDatasetV2Modal';

const StatsGroup = styled(Statistic.Group)`
  margin: 0 -0.5em !important;
  align-items: center !important;

  & > .statistic {
    margin: 0 0.5em !important;
  }
`;

type StatsProps = {
  counts?: DatasetStatsCounts;
};

const Stats = ({ counts: c }: StatsProps) => {
  return (
    <StatsGroup size="mini">
      <Statistic color={c?.success ? 'green' : undefined}>
        <Statistic.Value>{c?.success || '-'}</Statistic.Value>
      </Statistic>
      <span style={{ color: theme.gray }}>/</span>
      <Popup
        style={{ zIndex: 10000 }}
        trigger={
          <Statistic color={c?.error ? 'red' : undefined}>
            <Statistic.Value>{c?.error || '-'}</Statistic.Value>
          </Statistic>
        }
      >
        <Popup.Content>
          <div style={{ color: theme.gray }}>Errors</div>
          <Table definition>
            <Table.Row>
              <Table.Cell>Required</Table.Cell>
              <Table.Cell>{c?.required || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Invalid</Table.Cell>
              <Table.Cell>{c?.invalid || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Filter</Table.Cell>
              <Table.Cell>{c?.filter || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Duplicate</Table.Cell>
              <Table.Cell>{c?.duplicate || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cross Duplicate</Table.Cell>
              <Table.Cell>{c?.cross_duplicate || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Blacklist</Table.Cell>
              <Table.Cell>{c?.blacklist || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Carrier</Table.Cell>
              <Table.Cell>{c?.carrier || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Connect</Table.Cell>
              <Table.Cell>{c?.connect || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Landline</Table.Cell>
              <Table.Cell>{c?.landline || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Leadscore</Table.Cell>
              <Table.Cell>{c?.leadscore || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Billing</Table.Cell>
              <Table.Cell>{c?.billing || 0}</Table.Cell>
            </Table.Row>
          </Table>
        </Popup.Content>
      </Popup>
    </StatsGroup>
  );
};

const AlltimeIngested = ({ datasetId, blacklists }: { datasetId: string; blacklists: number[] | null }) => {
  const { data: counts, isLoading } = useGetDatasetRowCountQuery(datasetId);
  if (isLoading) return <Loader active inline />;
  if (!counts) return <Label color="red">Failed to load count</Label>;

  return (
    <StatsGroup size="mini">
      <Statistic title="Total">
        <Statistic.Value>{counts?.total || '-'}</Statistic.Value>
      </Statistic>
      {/* <span style={{ color: theme.gray }}>/</span>
      <Statistic color="green" title="Dialable">
        <Statistic.Value>{counts?.dialable || 0}</Statistic.Value>
      </Statistic>
      <span style={{ color: theme.gray }}>/</span>
      <Popup
        style={{ zIndex: 10000 }}
        trigger={
          <Statistic color="red" title="Final">
            <Statistic.Value>{counts.final || 0}</Statistic.Value>
          </Statistic>
        }
      >
        <Popup.Content>
          <div style={{ color: theme.gray }}>Errors</div>
          <Table definition>
            {blacklists?.includes(1) && (
              <Table.Row>
                <Table.Cell>Blacklisted Global</Table.Cell>
                <Table.Cell>{counts.blacklisted_global || 0}</Table.Cell>
              </Table.Row>
            )}
            {blacklists?.includes(2) && (
              <Table.Row>
                <Table.Cell>Blacklisted Federal</Table.Cell>
                <Table.Cell>{counts.blacklisted_federal || 0}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Blacklisted Client</Table.Cell>
              <Table.Cell>{counts.blacklisted_client || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Carrier Excluded</Table.Cell>
              <Table.Cell>{counts.carriers || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Connects Excluded</Table.Cell>
              <Table.Cell>{counts.connects || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Leadscore Failed</Table.Cell>
              <Table.Cell>{counts.leadscores || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>API Rejected</Table.Cell>
              <Table.Cell>{counts.rejected || 0}</Table.Cell>
            </Table.Row>
          </Table>
        </Popup.Content>
      </Popup> */}
    </StatsGroup>
  );
};

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell collapsing></Table.Cell>
  </Table.Row>
);

const ListDatasetsV2Body = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: datasets, isLoading } = useListDatasetsQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof datasets !== 'undefined') {
      count = Math.ceil(datasets.total / limit);
    }
    setPageCount(count);
  }, [datasets, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!datasets?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={10}>
          <span style={{ color: theme.gray }}>No datasets found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {datasets?.data.map(d => (
        <Table.Row key={d.id}>
          <Table.Cell collapsing>
            <Label title={d.created_at}>
              {formatDistanceToNow(new Date(d.created_at), { addSuffix: true, includeSeconds: true })}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <LinkWithDetail to={`/datasets/v2/${d.id}`} text={d.name} detail={d.id} />
          </Table.Cell>
          <Table.Cell>
            <StatsGroup size="mini">
              <Statistic>
                <Statistic.Value>
                  <AlltimeIngested datasetId={d.id} blacklists={d.rules.blacklists} />
                </Statistic.Value>
              </Statistic>
            </StatsGroup>
          </Table.Cell>
          <Table.Cell>
            <Stats counts={d.stats?.ingested_24h} />
          </Table.Cell>
          <Table.Cell>
            <Stats counts={d.stats?.ingested_1h} />
          </Table.Cell>
          <Table.Cell>
            <Stats counts={d.stats?.ingested_5m} />
          </Table.Cell>
          <Table.Cell>
            <StatsGroup size="mini">
              <Statistic title="Ingesting">
                <Statistic.Value>{d.stats?.queued || '-'}</Statistic.Value>
              </Statistic>
            </StatsGroup>
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            {!!d.id && (
              <>
                <UploaToDatasetV2Modal dataset={d} />
                <Link to={{ pathname: `/datasets/v2/${d.id}/docs` }}>
                  <Button as="div" color="blue" icon title="API Docs">
                    <Icon name="book" />
                  </Button>
                </Link>
                <Link to={{ pathname: `/datasets/v2/${d.id}` }}>
                  <Button as="div" color="blue" icon title="Edit Dataset">
                    <Icon name="pencil" />
                  </Button>
                </Link>
                <DeleteDatasetV2 id={d.id} />
              </>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListDatasetsV2Body;
