import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  Checkbox,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Input,
  InputOnChangeData,
  Label,
  Popup,
  Select,
  Table,
  TextArea,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useQualifaiAudioSttMutation } from 'src/api/qualifai-audio';
import { useUpdateQualifaiConversationMutation } from 'src/api/qualifai-conversations';
import { useGetQualifaiDialogflowBotQuery, useListAllQualifaiDialogflowBotsQuery } from 'src/api/qualifai-dialogflow';
import { useListQualifaiScorecardsQuery } from 'src/api/qualifai-scorecards';
import ApiMessage from 'src/components/ApiMessage';
import PublicFileUploader from 'src/components/ImageUploader';
import { AccordionTitleText, Note, StyledFieldset } from 'src/styles';
import {
  DEFAULT_BOT_PROJECT_ID,
  QualifaiConversation,
  QualifaiConversationNode,
  QualifaiConversationNodeActions,
  SpeechToTextProviders,
} from 'src/types';
import { getAudioDuration } from 'src/utils';
import { Layout, ReorderButton } from './style';

const PRETRANSFER_BLACKLIST_DISPOS = ['DNC', 'Irate', 'Prank', 'Prank Minor', 'Profanity'];

type ValidationErrors = {
  // General
  name?: string;
  sharedOutputs?: Record<string, string>[];
  nodes?: Record<string, Record<string, string>>;
  // Inbound
  // greetingAudioUrl?: string;
  // Outbound
  // segueAudioUrl?: string;
};

const getInitialFormdata = (conversation: QualifaiConversation): QualifaiConversation => {
  const next = _cloneDeep(conversation);

  // Set any missing defaults and/or apply data type conversions
  // if (next.schedule && !next.schedule.timezone) {
  //   next.schedule.timezone = 'America/New_York';
  // }

  return next;
};

type Props = {
  conversation: QualifaiConversation;
};

const EditQualifaiConversationForm = ({ conversation }: Props) => {
  const { data: bots, isLoading: botsLoading } = useListAllQualifaiDialogflowBotsQuery();
  const { data: scorecards, isLoading: scorecardsLoading } = useListQualifaiScorecardsQuery();
  const { data: user } = useGetUserProfileQuery();
  const { mutateAsync, isLoading: saveLoading } = useUpdateQualifaiConversationMutation();
  const { mutateAsync: stt, isLoading: sttIsLoading } = useQualifaiAudioSttMutation();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [apiErrors, setApiErrors] = useState<Record<string, string>>({});
  const [isValid, setIsValid] = useState(false);
  const [formdata, setFormdata] = useState<QualifaiConversation>(() => getInitialFormdata(conversation));
  const [saved, setSaved] = useState(true);
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoadingAudioFile, setIsLoadingAudioFile] = useState(false);

  const { data: selectedBot, isLoading: botLoading } = useGetQualifaiDialogflowBotQuery(
    formdata.dialogflowProjectId || DEFAULT_BOT_PROJECT_ID
  );

  const conversationIntents = useMemo(
    () =>
      selectedBot?.intents
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(i => ({
          key: i.name,
          value: i.name,
          text: i.name,
        })) || [],
    [selectedBot?.intents]
  );

  const getConversationIntents = useCallback(
    (intent: string) => {
      const updatedIntents = [...conversationIntents];
      const isIntentPresent = updatedIntents.find(i => i.value === intent);
      if (!isIntentPresent) {
        updatedIntents.push({
          key: intent,
          value: intent,
          text: intent,
        });
      }
      return updatedIntents;
    },
    [conversationIntents]
  );

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  const validate = useCallback(
    (c: QualifaiConversation) => {
      let errors = {} as ValidationErrors;

      // General
      if (!c.name.trim()) {
        errors.name = 'Name is required';
      }

      // Action and next node validation for intents
      if (c.sharedOutputs.length) {
        const sharedOutputErrors: Record<string, string>[] = [];
        c.sharedOutputs.forEach((n, i) => {
          if (!n.action) {
            sharedOutputErrors[i] = { action: 'Action is required' };
          } else {
            if (n.action === 'continue' && !n.nodeId) {
              sharedOutputErrors[i] = { nodeId: 'Next Node is required' };
            }
          }

          if (n.contextName && !conversationIntents.find(c => c.value === n.contextName)) {
            sharedOutputErrors[i] = {
              ...sharedOutputErrors[i],
              intent: `The selected bot does not have '${n.contextName}' intent`,
            };
          }
        });
        if (sharedOutputErrors.length) {
          errors.sharedOutputs = sharedOutputErrors;
        }
      }

      // Action and next node validation for questions
      if (!isEmpty(c.nodes)) {
        const nodeErrors: Record<string, Record<string, string>> = {};
        Object.entries(c.nodes).forEach(([nodeId, node]) => {
          if (!node.action) {
            nodeErrors[nodeId] = { action: 'Action is required' };
          } else {
            if (node.action === 'continue' && !node.nextNodeId) {
              nodeErrors[nodeId] = { nextNodeId: 'Next Node is required' };
            }
          }
        });
        if (!isEmpty(nodeErrors)) {
          errors.nodes = nodeErrors;
        }
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [setIsValid, viewErrors, conversationIntents]
  );

  useEffect(() => {
    validate(formdata);
  }, [formdata, validate]);

  const saveConversation = useCallback(
    async (c: QualifaiConversation, force = false) => {
      if (saved && !force) {
        return;
      }

      setApiMessage(undefined);

      try {
        await mutateAsync(c);
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, saved]
  );

  const onChange = useCallback(
    (_, { checked, name, value }) => {
      setFormdata(prev => {
        let v = typeof checked !== 'undefined' ? checked : value;
        if (name.includes('audioDuration')) {
          v = Number(v);
          if (Number.isNaN(v)) {
            v = 0;
          }
        }

        const next = _cloneDeep(prev);
        _set(next, name, v);

        validate(next);

        return next;
      });
      setSaved(false);
    },
    [validate]
  );

  const addSharedOutput = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      next.sharedOutputs.push({
        contextName: '',
        action: '',
        nodeId: '',
      });
      return next;
    });
    setSaved(false);
  }, []);

  const removeSharedOutput = useCallback(
    (index: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);
        next.sharedOutputs.splice(index, 1);
        return next;
      });
      setSaved(false);
    },
    []
  );

  const addNode = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      const newNode = {
        id: uuid(),
        order: Object.values(prev.nodes).reduce((acc, n) => (n.order > acc ? n.order : acc), 0) + 1,
        name: `Node ${Object.keys(prev.nodes).length + 1}`,
        nextNodeId: '',
        allowInterruptions: false,
        audioUrl: '',
        audioTranscript: '',
        audioDuration: 0,
        action: '',
        outputs: [],
        data: {},
      } as QualifaiConversationNode;

      next.nodes[newNode.id] = newNode;

      setActiveIndex(newNode.order);

      return next;
    });
    setSaved(false);
  }, []);

  const removeNode = useCallback(
    (id: string) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);
        delete next.nodes[id];
        return next;
      });
      setSaved(false);
      setActiveIndex(0);
    },
    []
  );

  const addNodeOutput = useCallback(
    (id: string) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);
        let outputs = next.nodes[id].outputs;
        if (!outputs) {
          outputs = [];
        }
        outputs.push({
          contextName: '',
          action: '',
          nodeId: '',
        });
        next.nodes[id].outputs = outputs;
        return next;
      });
      setSaved(false);
    },
    []
  );

  const removeNodeOutput = useCallback(
    (id: string, index: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);
        let outputs = next.nodes[id].outputs;
        if (!outputs) {
          outputs = [];
          next.nodes[id].outputs = outputs;
          return next;
        }
        outputs.splice(index, 1);
        next.nodes[id].outputs = outputs;
        return next;
      });
      setSaved(false);
    },
    []
  );

  const addScorecardDataMapping = useCallback(
    (id: string) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const data = next.nodes[id].data ?? {
          scorecard_data_mappings: [],
        };

        if (typeof data.scorecard_data_mappings === 'undefined') {
          data.scorecard_data_mappings = [];
        }

        data.scorecard_data_mappings.push({
          item_id: '',
          mapping_type: 'answer',
          field_name: '',
          parse: false,
          regexp: '',
        });

        next.nodes[id].data = data;

        return next;
      });
      setSaved(false);
    },
    []
  );

  const removeScorecardDataMapping = useCallback(
    (id: string, index: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const data = next.nodes[id].data;

        if (typeof data?.scorecard_data_mappings === 'undefined') {
          return next;
        }

        data.scorecard_data_mappings.splice(index, 1);

        next.nodes[id].data = data;

        return next;
      });
      setSaved(false);
    },
    []
  );

  const addScorecardTransferOverride = useCallback(
    (id: string) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const data = next.nodes[id].data ?? {
          scorecard_transfer_overrides: [],
        };

        if (typeof data.scorecard_transfer_overrides === 'undefined') {
          data.scorecard_transfer_overrides = [];
        }

        data.scorecard_transfer_overrides.push({
          disposition: '',
          override_type: 'allow_transfer',
          transfer_to: '',
        });

        next.nodes[id].data = data;

        return next;
      });
      setSaved(false);
    },
    []
  );

  const removeScorecardTransferOverride = useCallback(
    (id: string, index: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);

        const data = next.nodes[id].data;

        if (typeof data?.scorecard_transfer_overrides === 'undefined') {
          return next;
        }

        data.scorecard_transfer_overrides.splice(index, 1);

        next.nodes[id].data = data;

        return next;
      });
      setSaved(false);
    },
    []
  );

  const toggleViewErrors = () => setViewErrors(prev => !prev);

  const onTranscribeAudio = useCallback(
    (nodeId: string) => async () => {
      const node = formdata.nodes[nodeId];
      if (!node.audioUrl) {
        return;
      }

      try {
        const text = await stt({ url: node.audioUrl });
        setFormdata(prev => {
          const next = _cloneDeep(prev);
          next.nodes[nodeId].audioTranscript = text;
          return next;
        });
        setSaved(false);
      } catch (e: any) {
        setApiErrors(prev => {
          const next = { ...prev };
          const msg = apiErrorHandler(e);
          prev.stt = typeof msg.message === 'string' ? msg.message : e.message;
          return next;
        });

        setTimeout(() => {
          setApiErrors(prev => {
            const next = { ...prev };
            delete prev.stt;
            return next;
          });
        }, 3000);
      }
    },
    [formdata.nodes, stt]
  );

  const updateAudioDuration = useCallback(
    (event: any, { checked, name, value }: InputOnChangeData) => {
      if (!!value) {
        setIsLoadingAudioFile(true);
        getAudioDuration(value)
          .then(duration => {
            const durationInMs = Math.trunc(duration * 1000);
            const durationNodeName = name.replace('audioUrl', 'audioDuration');
            onChange(event, { checked, name: durationNodeName, value: durationInMs });
            setIsLoadingAudioFile(false);
          })
          .catch(_ => setIsLoadingAudioFile(false));
      }
    },
    [onChange]
  );

  const resetToAudioFileDuration = (name: string) => {
    const value = _get(formdata, name);
    updateAudioDuration('', { checked: undefined, name, value });
  };

  const setAudioDurationAndFileUrl = (
    event: SyntheticEvent<HTMLInputElement>,
    { checked, name, value }: InputOnChangeData
  ) => {
    updateAudioDuration(event, { checked, name, value });
    onChange(event, { checked, name, value });
  };

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => saveConversation(formdata, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'general' }}>
          <Header>General</Header>

          <Form.Checkbox toggle label="Enabled" name="enabled" checked={formdata.enabled} onChange={onChange} />

          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

          <Form.Select
            label="Start Node"
            name="startNode"
            value={formdata.startNode}
            onChange={onChange}
            options={Object.values(formdata.nodes)
              .filter(n => n.order >= 0)
              .map(n => ({ key: n.id, value: n.id, text: n.name }))}
          />

          {user?.role === 'admin' && (
            <StyledFieldset>
              <legend>Admin only</legend>

              <Form.Select
                clearable
                placeholder={bots?.data.find(b => b.projectId === DEFAULT_BOT_PROJECT_ID)?.name || ''}
                label="Dialogflow Bot"
                name="dialogflowProjectId"
                value={formdata.dialogflowProjectId}
                onChange={onChange}
                options={bots?.data.map(p => ({ key: p.projectId, value: p.projectId, text: p.name })) || []}
                loading={botsLoading}
              />

              <Form.Select
                clearable
                placeholder="AssemblyAI Streaming"
                label="Speech to Text Provider"
                name="sttProvider"
                value={formdata.sttProvider}
                onChange={onChange}
                options={SpeechToTextProviders.map(p => ({ ...p, key: p.value }))}
              />

              <Header>Global Intents</Header>
              <Note>
                When any of the following intents are detected on any question, the call will proceed to the specified
                node.
              </Note>

              {formdata.sharedOutputs.length > 0 && (
                <Table celled>
                  <Table.Body>
                    {formdata.sharedOutputs.map((output, index) => (
                      <Table.Row key={`sharedOutput-${index}`}>
                        <Table.Cell>
                          <Form.Select
                            label="Intent Name"
                            name={`sharedOutputs.${index}.contextName`}
                            onChange={onChange}
                            value={output.contextName}
                            options={getConversationIntents(output.contextName)}
                            loading={botLoading}
                            error={errors.sharedOutputs && errors.sharedOutputs[index]?.intent}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Form.Select
                            label="Action"
                            name={`sharedOutputs.${index}.action`}
                            onChange={onChange}
                            value={output.action || ''}
                            options={QualifaiConversationNodeActions.filter(a => a.value !== 'transfer').map(a => ({
                              ...a,
                              key: a.value,
                            }))}
                            error={errors.sharedOutputs && errors.sharedOutputs[index]?.action}
                          />

                          {output.action === 'continue' && (
                            <Form.Select
                              clearable
                              label="Node"
                              name={`sharedOutputs.${index}.nodeId`}
                              onChange={onChange}
                              value={output.nodeId || ''}
                              options={Object.values(formdata.nodes).map(n => ({
                                key: n.id,
                                value: n.id,
                                text: n.name,
                              }))}
                              error={errors.sharedOutputs && errors.sharedOutputs[index]?.nodeId}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <Button type="button" icon color="red" onClick={removeSharedOutput(index)}>
                            <Icon name="trash" />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}

              <Button type="button" size="mini" compact color="blue" onClick={addSharedOutput}>
                <Icon name="plus" />
                Add Output
              </Button>
            </StyledFieldset>
          )}
        </div>

        <div style={{ gridArea: 'nodes' }}>
          <Header>Questions</Header>

          <Accordion fluid styled style={{ marginBottom: '1rem' }}>
            {Object.values(formdata.nodes)
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((node, i) => {
                const selectedScorecard = scorecards?.find(s => s.id === node.data?.pretransfer_scorecard_id);

                return (
                  <div key={node.id} style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 0.5rem',
                      }}
                    >
                      <ReorderButton
                        disabled={i === 0}
                        type="button"
                        onClick={() => {
                          setFormdata(prev => {
                            const next = _cloneDeep(prev);

                            const prevNode = Object.values(next.nodes).find(n => n.order === node.order - 1);
                            if (prevNode) {
                              next.nodes[prevNode.id].order = node.order;
                            }

                            next.nodes[node.id].order = node.order - 1;

                            return next;
                          });

                          if (activeIndex === i) {
                            setActiveIndex(i - 1);
                          }
                        }}
                      >
                        <Icon name="chevron up" />
                      </ReorderButton>

                      <ReorderButton
                        disabled={i === Object.values(formdata.nodes).length - 1}
                        type="button"
                        onClick={() => {
                          setFormdata(prev => {
                            const next = _cloneDeep(prev);

                            const nextNode = Object.values(next.nodes).find(n => n.order === node.order + 1);
                            if (nextNode) {
                              next.nodes[nextNode.id].order = node.order;
                            }

                            next.nodes[node.id].order = node.order + 1;

                            return next;
                          });

                          if (activeIndex === i) {
                            setActiveIndex(i + 1);
                          }
                        }}
                      >
                        <Icon name="chevron down" />
                      </ReorderButton>
                    </div>

                    <AccordionTitle
                      active={activeIndex === i}
                      onClick={() => setActiveIndex(i === activeIndex ? -1 : i)}
                      style={i === 0 ? { borderTop: 0 } : undefined}
                    >
                      <Icon name="dropdown" />
                      <AccordionTitleText error={!!(errors.nodes && errors.nodes[node.id])}>
                        {node.name} {!!(errors.nodes && errors.nodes[node.id]) && <Icon name="exclamation triangle" />}
                      </AccordionTitleText>
                    </AccordionTitle>

                    <AccordionContent active={activeIndex === i}>
                      <Form.Group>
                        <Form.Input
                          width={12}
                          label="Name"
                          name={`nodes.${node.id}.name`}
                          onChange={onChange}
                          value={node?.name || ''}
                        />

                        <Form.Field width={4}>
                          <label>Allow Interruptions</label>
                          <Form.Checkbox
                            toggle
                            name={`nodes.${node.id}.allowInterruptions`}
                            checked={node?.allowInterruptions}
                            onChange={onChange}
                          />
                        </Form.Field>
                      </Form.Group>

                      {node?.order >= 0 && (
                        <>
                          <Form.Field>
                            <Button
                              type="button"
                              size="mini"
                              compact
                              color="blue"
                              style={{ position: 'relative', top: -4, float: 'right', margin: 0 }}
                              disabled
                            >
                              <Icon name="arrow down" />
                              Convert Text to Speech
                            </Button>

                            <label>Audio Transcript</label>
                            <TextArea
                              name={`nodes.${node.id}.audioTranscript`}
                              onChange={onChange}
                              value={node?.audioTranscript || ''}
                            />
                          </Form.Field>

                          <Form.Group>
                            <Form.Field width={12}>
                              <Button
                                type="button"
                                size="mini"
                                compact
                                color={apiErrors.stt ? 'red' : 'blue'}
                                style={{ position: 'relative', top: -4, float: 'right', margin: 0 }}
                                onClick={!apiErrors.stt ? onTranscribeAudio(node.id) : undefined}
                                loading={sttIsLoading}
                              >
                                <Icon name={apiErrors.stt ? 'warning sign' : 'arrow up'} />
                                {apiErrors.stt || 'Transcribe Audio'}
                              </Button>

                              <label>Audio URL</label>
                              <Input
                                name={`nodes.${node.id}.audioUrl`}
                                onChange={setAudioDurationAndFileUrl}
                                value={node.audioUrl}
                                style={{ marginBottom: '1rem' }}
                              />
                              <PublicFileUploader
                                fileType="audio"
                                name={`nodes.${node.id}.audioUrl`}
                                onChange={onChange}
                                value={node.audioUrl}
                              />
                            </Form.Field>

                            <Form.Field width={4}>
                              <Button
                                type="button"
                                size="mini"
                                compact
                                color="blue"
                                style={{ position: 'relative', top: -4, float: 'right', margin: 0 }}
                                icon
                                disabled={!node.audioUrl}
                                onClick={() => resetToAudioFileDuration(`nodes.${node.id}.audioUrl`)}
                              >
                                <Icon name="refresh" />
                              </Button>

                              <label>Audio Duration (ms)</label>
                              <Input
                                name={`nodes.${node.id}.audioDuration`}
                                onChange={onChange}
                                value={node?.audioDuration || ''}
                                style={{ marginBottom: '0.5rem' }}
                                loading={isLoadingAudioFile}
                              />
                            </Form.Field>
                          </Form.Group>
                        </>
                      )}

                      <Form.Select
                        clearable
                        label="Action"
                        placeholder="None"
                        name={`nodes.${node.id}.action`}
                        onChange={onChange}
                        options={QualifaiConversationNodeActions.map(a => ({ ...a, key: a.value }))}
                        value={node?.action || ''}
                        error={errors.nodes && errors.nodes[node.id]?.action}
                      />

                      {node?.action === 'continue' && (
                        <Form.Select
                          clearable
                          label="Next Node"
                          name={`nodes.${node.id}.nextNodeId`}
                          onChange={onChange}
                          value={node?.nextNodeId || ''}
                          options={Object.values(formdata.nodes)
                            .filter(n => n.order >= 0 && n.id !== node.id)
                            .map(n => ({ key: n.id, value: n.id, text: n.name }))}
                          error={errors.nodes && errors.nodes[node.id]?.nextNodeId}
                        />
                      )}

                      {node?.action === 'transfer' && (
                        <>
                          <Form.Select
                            search
                            clearable
                            loading={scorecardsLoading}
                            label="Pre-Transfer Evaluation Scorecard"
                            name={`nodes.${node.id}.data.pretransfer_scorecard_id`}
                            onChange={onChange}
                            value={node?.data?.pretransfer_scorecard_id || ''}
                            options={scorecards?.map(s => ({ key: s.id, value: s.id, text: s.title })) || []}
                          />

                          <Form.Field>
                            <label>Transfer Overrides</label>
                            <Note>
                              By default we allow <strong>Qualified</strong> and <strong>Qualified Hesitant</strong>{' '}
                              dispositions through to the transfer number specified in the voice config. If you would
                              like to allow other dispositions to transfer you can add them here. You also have the
                              option to override the transfer number based on the disposition.
                            </Note>
                            <Note>
                              By default we blacklist the following dispositions:{' '}
                              {PRETRANSFER_BLACKLIST_DISPOS.map((d, i) => (
                                <Fragment key={d}>
                                  <strong>{d}</strong>
                                  {i < PRETRANSFER_BLACKLIST_DISPOS.length - 1 ? ', ' : ''}
                                </Fragment>
                              ))}
                              . This can not be overridden.
                            </Note>

                            {typeof node.data?.scorecard_transfer_overrides !== 'undefined' &&
                              node.data?.scorecard_transfer_overrides.length > 0 && (
                                <Table celled>
                                  <Table.Body>
                                    {node.data?.scorecard_transfer_overrides.map((m: any, i: number) => (
                                      <Table.Row key={i}>
                                        <Table.Cell>
                                          <Form.Select
                                            clearable
                                            search
                                            label="Disposition"
                                            name={`nodes.${node.id}.data.scorecard_transfer_overrides.${i}.disposition`}
                                            onChange={onChange}
                                            value={m.disposition || ''}
                                            options={(selectedScorecard?.sections || []).reduce((acc, s) => {
                                              const item = s.scorecardItems.find(i => i.order === 0);
                                              if (!item) return acc;

                                              item.selectOptions
                                                .filter(({ label }) => !PRETRANSFER_BLACKLIST_DISPOS.includes(label))
                                                .forEach(({ label }) => {
                                                  acc.push({
                                                    key: label,
                                                    value: label,
                                                    text: label,
                                                  });
                                                });

                                              return acc;
                                            }, [] as DropdownItemProps[])}
                                          />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Form.Field>
                                            <label>Override Type</label>
                                            <Select
                                              clearable
                                              placeholder="Allow Transfer"
                                              name={`nodes.${node.id}.data.scorecard_transfer_overrides.${i}.override_type`}
                                              onChange={onChange}
                                              value={m.override_type || ''}
                                              options={[
                                                {
                                                  key: 'allow_transfer',
                                                  value: 'allow_transfer',
                                                  text: 'Allow Transfer',
                                                },
                                                { key: 'transfer_to', value: 'transfer_to', text: 'Transfer To' },
                                              ]}
                                            />
                                          </Form.Field>

                                          {m.override_type === 'transfer_to' && (
                                            <Form.Input
                                              placeholder="+12223334444"
                                              label="Phone Number"
                                              name={`nodes.${node.id}.data.scorecard_transfer_overrides.${i}.transfer_to`}
                                              onChange={onChange}
                                              value={m.transfer_to || ''}
                                            />
                                          )}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                          <Button
                                            type="button"
                                            icon
                                            color="red"
                                            onClick={removeScorecardTransferOverride(node.id, i)}
                                          >
                                            <Icon name="trash" />
                                          </Button>
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              )}

                            <Button
                              type="button"
                              color="blue"
                              size="mini"
                              compact
                              onClick={addScorecardTransferOverride(node.id)}
                            >
                              <Icon name="plus" />
                              Add Override
                            </Button>
                          </Form.Field>

                          <Form.Field>
                            <label>Data Mappings</label>
                            {typeof node.data?.scorecard_data_mappings !== 'undefined' &&
                              node.data?.scorecard_data_mappings.length > 0 && (
                                <Table celled>
                                  <Table.Body>
                                    {node.data?.scorecard_data_mappings.map((m: any, i: number) => (
                                      <Table.Row key={i}>
                                        <Table.Cell>
                                          <Form.Select
                                            clearable
                                            search
                                            label="Scorecard Item"
                                            name={`nodes.${node.id}.data.scorecard_data_mappings.${i}.item_id`}
                                            onChange={onChange}
                                            value={m.item_id || ''}
                                            options={(selectedScorecard?.sections || []).reduce((acc, s) => {
                                              s.scorecardItems.forEach(i => {
                                                acc.push({
                                                  key: i.id,
                                                  value: i.id,
                                                  text: i.question,
                                                  description: i.reportingLabel,
                                                });
                                              });
                                              return acc;
                                            }, [] as DropdownItemProps[])}
                                          />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Form.Field>
                                            <div style={{ float: 'right' }}>
                                              <Checkbox
                                                label="Parse?"
                                                name={`nodes.${node.id}.data.scorecard_data_mappings.${i}.parse`}
                                                onChange={onChange}
                                                checked={m.parse}
                                              />
                                            </div>

                                            <label>Mapping Type</label>
                                            <Select
                                              clearable
                                              placeholder="Anwser"
                                              name={`nodes.${node.id}.data.scorecard_data_mappings.${i}.mapping_type`}
                                              onChange={onChange}
                                              value={m.mapping_type || ''}
                                              options={[
                                                { key: 'answer', value: 'answer', text: 'Answer' },
                                                { key: 'note', value: 'note', text: 'Note' },
                                              ]}
                                            />
                                          </Form.Field>

                                          {m.parse && (
                                            <Form.Field>
                                              <label>Regular Expression</label>
                                              <Input
                                                labelPosition="right"
                                                name={`nodes.${node.id}.data.scorecard_data_mappings.${i}.regexp`}
                                                onChange={onChange}
                                                value={m.regexp || ''}
                                              >
                                                <Label basic>/</Label>
                                                <input />
                                                <Label basic>/</Label>
                                              </Input>
                                            </Form.Field>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Form.Input
                                            label="Field Name"
                                            name={`nodes.${node.id}.data.scorecard_data_mappings.${i}.field_name`}
                                            onChange={onChange}
                                            value={m.field_name || ''}
                                          />
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                          <Button
                                            type="button"
                                            icon
                                            color="red"
                                            onClick={removeScorecardDataMapping(node.id, i)}
                                          >
                                            <Icon name="trash" />
                                          </Button>
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              )}

                            <Button
                              type="button"
                              color="blue"
                              size="mini"
                              compact
                              onClick={addScorecardDataMapping(node.id)}
                            >
                              <Icon name="plus" />
                              Add Data Mapping
                            </Button>
                          </Form.Field>
                        </>
                      )}

                      <Form.Field>
                        <label>Output(s)</label>

                        {(node?.outputs || []).length > 0 && (
                          <Table celled>
                            <Table.Body>
                              {(node?.outputs || []).map((output, index) => (
                                <Table.Row key={`node-output-${index}`}>
                                  <Table.Cell>
                                    <Form.Select
                                      label="Intent Name"
                                      name={`nodes.${node.id}.outputs.${index}.contextName`}
                                      onChange={onChange}
                                      value={output.contextName || ''}
                                      options={conversationIntents}
                                      loading={botLoading}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Form.Select
                                      label="Node"
                                      name={`nodes.${node.id}.outputs.${index}.nodeId`}
                                      onChange={onChange}
                                      value={output.nodeId || ''}
                                      options={Object.values(formdata.nodes).map(n => ({
                                        key: n.id,
                                        value: n.id,
                                        text: n.name,
                                      }))}
                                    />
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <Button type="button" icon color="red" onClick={removeNodeOutput(node.id, index)}>
                                      <Icon name="trash" />
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        )}

                        <Button type="button" size="mini" compact color="blue" onClick={addNodeOutput(node.id)}>
                          <Icon name="plus" />
                          Add Output
                        </Button>
                      </Form.Field>

                      <Button type="button" color="red" basic onClick={removeNode(node.id)}>
                        <Icon name="trash" />
                        Delete Node
                      </Button>
                    </AccordionContent>
                  </div>
                );
              })}
          </Accordion>

          <Button type="button" size="mini" compact color="blue" onClick={addNode}>
            <Icon name="plus" />
            Add Question
          </Button>
        </div>
      </Layout>
    </Form>
  );
};

export default EditQualifaiConversationForm;
