import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Form, Icon } from 'semantic-ui-react';

import { useListAllQualifaiConversationsQuery } from 'src/api/qualifai-conversations';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import { useGetVoiceRecordingDispositionsQuery, VoiceCallRecordingFilters } from 'src/api/voice-recordings';
import PaginatedTable from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { ReportingTimeRange, ReportingTimeRanges, VoiceCallDirection, VoiceCallDirections } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';
import ListVoiceCallRecordingsBody from './ListVoiceCallRecordingsBody';

const getFiltersFromQuery = (query: URLSearchParams): VoiceCallRecordingFilters => {
  const search = query.get('search') || undefined;

  const timeRange = (query.get('timeRange') as ReportingTimeRange) || 'this week';

  const [timeRangeStart, timeRangeEnd] = reportingTimeRangeToDateStrings(timeRange);

  const startDateStr = query.get('startDate') || timeRangeStart;
  const startDate = startDateStr ? parse(startDateStr, 'yyyy-MM-dd', new Date()) : new Date();

  const endDateStr = query.get('endDate') || timeRangeEnd;
  const endDate = endDateStr ? parse(endDateStr, 'yyyy-MM-dd', new Date()) : new Date();

  return {
    timeRange,
    startDate,
    endDate,
    search,
    directions: query.getAll('directions') as VoiceCallDirection[],
    dispositions: query.getAll('dispositions'),
    voiceConfigIDs: query.getAll('voiceConfigIDs'),
    conversationIDs: query.getAll('conversationIDs'),
  };
};

const VoiceCallRecordingsList = () => {
  const { data: dispositions, isLoading: dispositionsLoading } = useGetVoiceRecordingDispositionsQuery();
  const { data: voiceConfigs, isLoading: configsLoading } = useListVoiceConfigsQuery({ limit: 100, offset: 0 });
  const { data: conversations, isLoading: conversationsLoading } = useListAllQualifaiConversationsQuery({
    limit: 100,
    offset: 0,
  });

  const query = useSearchQuery();
  const { push } = useHistory();
  const [filters, setFilters] = useState<VoiceCallRecordingFilters>(getFiltersFromQuery(query));
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([filters.startDate, filters.endDate]);
  const [search, setSearch] = useState(filters.search || '');

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onChangeTimeRange = useCallback(
    (_, { value }) => {
      if (value === 'custom') {
        setFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange }));
      } else {
        const [startDateStr, endDateStr] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);

        const startDate = parse(startDateStr, 'yyyy-MM-dd', new Date());
        const endDate = parse(endDateStr, 'yyyy-MM-dd', new Date());

        setDaterange([startDate, endDate]);

        setFilters(prev => ({
          ...prev,
          timeRange: value as ReportingTimeRange,
          startDate,
          endDate,
        }));

        query.set('timeRange', value);
        query.set('startDate', format(startDate, 'yyyy-MM-dd'));
        query.set('endDate', format(endDate, 'yyyy-MM-dd'));
        query.set('page', '1');
        push({ search: query.toString() });
      }
    },
    [push, query]
  );

  const onChangeDates = useCallback(
    (d: [Date | null, Date | null]) => {
      setDaterange(d);
      const [startDate, endDate] = d;
      if (startDate !== null && endDate !== null) {
        setFilters(prev => ({
          ...prev,
          timeRange: 'custom' as ReportingTimeRange,
          startDate,
          endDate,
        }));

        query.set('startDate', format(startDate, 'yyyy-MM-dd'));
        query.set('endDate', format(endDate, 'yyyy-MM-dd'));
        query.set('page', '1');
        push({ search: query.toString() });
      }
    },
    [push, query]
  );

  const onChange = useCallback(
    (_, { name, value }) => {
      setFilters(prev => ({ ...prev, [name]: value }));

      query.delete(name);
      if (Array.isArray(value)) {
        value.forEach(v => query.append(name, v));
      } else {
        query.set(name, value);
      }

      query.set('page', '1');

      push({ search: query.toString() });
    },
    [push, query]
  );

  const onSubmit = useCallback(() => {
    setFilters(prev => ({ ...prev, search }));

    query.set('search', search);
    query.set('page', '1');
    push({ search: query.toString() });
  }, [push, query, search]);

  return (
    <Container>
      <Helmet>
        <title>Voice Recordings | datascore</title>
      </Helmet>

      <PageHeader style={{ marginBottom: '1rem' }}>
        <span style={{ color: theme.gray }}>Voice</span> <span style={{ padding: '0 0.5rem' }}>/</span> Recordings
      </PageHeader>

      <Form onSubmit={onSubmit}>
        <Row style={{ flexWrap: 'wrap', gap: '0.5rem' }}>
          <Form.Input
            label="Search"
            value={search}
            onChange={(_, { value }) => setSearch(value)}
            placeholder="Phone Number, Call ID, Recording ID"
            icon="search"
            iconPosition="left"
            style={{ width: 300 }}
          />

          <Form.Dropdown
            label="Direction(s)"
            multiple
            selection
            value={filters.directions}
            name="directions"
            onChange={onChange}
            options={VoiceCallDirections.map(d => ({ key: d, ...d }))}
            style={{ maxWidth: 200 }}
          />

          <Form.Dropdown
            label="Disposition(s)"
            multiple
            selection
            loading={dispositionsLoading}
            name="dispositions"
            onChange={onChange}
            options={dispositions?.map(d => ({ key: d, value: d, text: d })) || []}
            value={filters.dispositions}
            style={{ maxWidth: 200 }}
          />

          <Form.Dropdown
            label="Voice Config(s)"
            multiple
            selection
            loading={configsLoading}
            name="voiceConfigIDs"
            value={filters.voiceConfigIDs}
            onChange={onChange}
            options={voiceConfigs?.data.map(c => ({ key: c.id, value: c.id, text: c.name })) || []}
            style={{ maxWidth: 200 }}
          />

          <Form.Dropdown
            label="Conversation(s)"
            multiple
            selection
            loading={conversationsLoading}
            name="conversationIDs"
            value={filters.conversationIDs}
            onChange={onChange}
            options={conversations?.data.map(c => ({ key: c.id, value: c.id, text: c.name })) || []}
            style={{ maxWidth: 200 }}
          />

          <Form.Field style={{ marginBottom: 0 }}>
            <label style={{ width: 230 }}>
              Time Range{' '}
              <span style={{ opacity: 0.4, fontSize: '0.9em', whiteSpace: 'nowrap' }}>
                ({format(filters.startDate, 'yyyy-MM-dd')} - {format(filters.endDate, 'yyyy-MM-dd')})
              </span>
            </label>

            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.map(tr => ({
                ...tr,
                key: tr.value,
              }))}
              value={filters.timeRange}
            />
          </Form.Field>

          <Form.Field style={{ width: 240 }}>
            <label>Custom Range</label>
            <DatePicker
              selectsRange
              startDate={daterange[0]}
              endDate={daterange[1]}
              customInput={<Form.Input fluid icon="calendar" iconPosition="left" />}
              monthsShown={2}
              showPopperArrow={false}
              onChange={onChangeDates}
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button color="blue" icon>
              <Icon name="search" />
            </Button>
          </Form.Field>
        </Row>
      </Form>

      <PaginatedTable
        headers={[
          'Phone Number / Call ID',
          'Created At',
          'Direction',
          'Duration',
          'Voice Config',
          'Conversation',
          'Disposition',
          'Recording',
          '',
        ]}
        renderBody={props => <ListVoiceCallRecordingsBody {...props} filters={filters} />}
      />
    </Container>
  );
};

export default VoiceCallRecordingsList;
